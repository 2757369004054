$enable-negative-margins: true;

@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons.css";

.app-container {
  height: calc(100% - (#{$navbar-brand-height} + #{$navbar-brand-padding-y} * 2 + #{$navbar-padding-y} * 2 + #{$spacer} / 2));
}

.sidebar .card-body {
  height: calc(100% - 24px);
  overflow: scroll;
}

.dropzone {
  background-color: rgba($info, .1);
}

.dropzone-disabled {
  background-color: rgba($secondary, .1);
}
